table {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-collapse: separate;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  width: 100%;
  padding-bottom: 16px;
}

th {
  background-color: rgba(0, 0, 0,.1);
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-width: 1px;
  padding: .25rem .75rem
}

th:first-child {
  border-top-left-radius: .375rem
}

th:last-child {
  border-right-width: 1px;
  border-top-right-radius: .375rem
}

td {
  border-bottom-width: 1px;
  border-left-width: 1px;
  padding: .25rem .75rem
}

td:last-child {
  border-right-width: 1px
}

tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0,.1)
}

tbody tr:last-child td:first-child {
  border-bottom-left-radius: .375rem
}

tbody tr:last-child td:last-child {
  border-bottom-right-radius: .375rem
}