/* Slideshow.css */
.slideshow {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slide ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.slide li {
  margin: 0.5em 0;
}

.slide a {
  text-decoration: none;
  color: #333;
  font-size: 1.2em;
}

.slide a:hover {
  color: #007bff;
}
